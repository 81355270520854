import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Icon from '-!svg-react-loader!../assets/progso-logo.svg';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ transform: 'translate(-50%, -50%)', top:'50%', left:'50%', position:'absolute', textAlign:'center', fontFamily:"'Open Sans', sans-serif", fontWeight:'300' }}>
        <Icon style={{maxWidth:'400px'}}/>
        <div style={{marginTop:'2rem', border:'1px solid #dddddd', padding:'1.2rem 1.2rem 0rem 1.2rem', marginBottom:'2rem', borderRadius:'.5rem'}}>
            <h2 style={{fontWeight:'300', fontFamily:"'Open Sans', sans-serif"}}>under construction</h2>
        </div>
        <p style={{marginBottom:'0'}}><a style={{textDecoration:'none', color:'#000000'}} href="mailto:office@progso.com">office@progso.com</a></p>
        <p><a style={{textDecoration:'none', color:'#000000'}} href="tel:+48880111880">+48{'\u00A0'}880{'\u00A0'}111{'\u00A0'}880</a></p>
    </div>
  </Layout>
)

export default IndexPage
